import React, { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";

const AppPagination = ({ onPageChange, totalPages }) => {
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    onPageChange(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <Pagination.Item
            key={i}
            active={i === activePage}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      if (activePage > 3) {
        pageNumbers.push(
          <Pagination.First key="first" onClick={() => handlePageChange(1)} />
        );
        pageNumbers.push(
          <Pagination.Ellipsis
            key={-1}
            onClick={() => handlePageChange(activePage - 2)}
          />
        );
      }

      for (
        let i = Math.max(1, activePage - 1);
        i <= Math.min(activePage + 1, totalPages);
        i++
      ) {
        pageNumbers.push(
          <Pagination.Item
            key={i}
            active={i === activePage}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </Pagination.Item>
        );
      }

      if (activePage < totalPages - 2) {
        pageNumbers.push(
          <Pagination.Ellipsis
            key={-2}
            onClick={() => handlePageChange(activePage + 2)}
          />
        );
        pageNumbers.push(
          <Pagination.Item
            key={totalPages}
            active={totalPages === activePage}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </Pagination.Item>
        );
      }
    }

    if (activePage < totalPages) {
      pageNumbers.push(
        <Pagination.Next
          key="next"
          onClick={() => handlePageChange(activePage + 1)}
        />
      );
    }

    return pageNumbers;
  };

  return (
    <Pagination>
      <Pagination.Prev
        disabled={activePage === 1}
        onClick={() => handlePageChange(activePage - 1)}
      />
      {renderPageNumbers()}
    </Pagination>
  );
};

export default AppPagination;
