import React, { useState, useEffect } from "react";

import styles from "./PageWizard.module.css";
import Input from "../../components/Basic/Input";
import ComboBox from "../../components/Basic/ComboBox";
import Toggle from "../../components/Basic/Toggle";
import Uploader from "../../components/Basic/Uploader";
import RadioButton from "../../components/Basic/RadioButton";
import { v4 as uuidv4 } from "uuid";
import Modal from "../../components/Basic/Modal";
import ImageStorage from "../ImageStorage/ImageStorage";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Richtext from "../../components/Basic/Richtext";
import {
  DeleteFile,
  DeleteVideo,
  GetPageFiles,
  GetPageVideos,
  UpdateFileAction,
  UpdatePage,
  UpdateVideos,
} from "../../services/apiService";

import { useHistory } from "react-router-dom";
import { Skeleton } from "antd";
import { getThumbnail, getTrailData } from "../../helpers/GlobalHelper";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ParentsList from "../../components/ParentsList/ParentsList";
import FileUploader from "../../components/Basic/FileUploader";

const PageWizard = ({
  projectLanguages,
  projectPagesBasic,
  projectParents,
  GetPage,
  SetPage,
  GetPageBasic,
  projectImages,
  setProjectImages,
  isLoading,
}) => {
  const { levelId, topLevelId, languageId } = useParams();
  const [pageValues, setPageValues] = useState(
    levelId && topLevelId && languageId
      ? GetPage(levelId, topLevelId, languageId)
      : {}
  );

  const [isDisabled, setIsDisabled] = useState(false);
  // const [addableLanguages, setAddableLanguages] = useState([
  //   { value: "eng", label: "English" },
  // ]);
  const [addableLanguages, setAddableLanguages] = useState(
    projectLanguages && projectLanguages.length > 0
      ? projectLanguages.map((x) => ({
          value: x.languageId,
          label: x.languageId,
        }))
      : []
  );
  const history = useHistory();
  const [pageBasic, setPageBasic] = useState(null);
  const [validation, setValidation] = useState({});
  const [showHideRichTextEditor, setShowHideRichTextEditor] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [files, setFiles] = useState([]);
  const [isImageModalActive, setIsImageModalActive] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isLoadingFiles, setIsLoadingFiles] = useState(
    topLevelId ? true : false
  );
  const [isLoadingVideos, setIsLoadingVideos] = useState(
    topLevelId ? true : false
  );
  const [isVideosInited, setIsVideosInited] = useState(false);
  const [isFilesInited, setIsFilesInited] = useState(false);
  const [isChangeLocationState, setIsChangeLocationState] = useState(false);

  const [pageSupportLanguages, setPageSupportLanguages] = useState([]);
  const [newFileIdState, setNewFileIdState] = useState(null);
  const [trail, setTrail] = useState([]);
  const [deletingFileId, setDeletingFileId] = useState(null);
  const [changingFileIdAction, setChangingFileIdAction] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    // This effect will run after deleteStateId changes
    // You can perform any side effects or additional logic here
    // Forcing a re-render if needed
    setForceUpdate((prev) => !prev);
  }, [newFileIdState]);

  useEffect(() => {
    setIsDisabled(true);
    if (topLevelId) {
      const currPage = GetPageBasic(topLevelId);
      setPageBasic(currPage);
      setPageSupportLanguages(currPage ? currPage.pages : []);

      if (currPage) {
        initPageFiles(levelId);
        if (!isVideosInited) {
          initPageVideos(levelId);
        }
        console.log({ currPage });
        const trailTmp = getTrailData(
          currPage.title.topLevelId,
          projectParents
        );
        if (trailTmp && trailTmp.length > 0) {
          const trailRev = trailTmp.reverse();
          setTrail(trailRev);
          console.log({ trailRev });
        }
      }
    } else {
      setPageValues({ levelId: uuidv4() });
      setIsFileLoading(false);
      setIsVideoLoading(false);
      setIsFilesInited(true);
      setIsVideosInited(true);
    }

    setIsLoadingPage(false);
    setIsDisabled(false);
  }, []);

  useEffect(() => {
    setIsLoadingPage(true);
    setIsDisabled(true);

    if (topLevelId) {
      setTimeout(function () {
        const found =
          levelId && topLevelId && languageId
            ? GetPage(levelId, topLevelId, languageId)
            : {};
        console.log({ found });
        setPageValues(found);
        initPageFiles(levelId);
        if (!isVideosInited) {
          initPageVideos(levelId);
        }
        setIsLoadingPage(false);
        setIsDisabled(false);
      }, 100);
    } else {
      setPageValues({ levelId: uuidv4() });
      setIsFileLoading(false);
      setIsVideoLoading(false);
      setIsFilesInited(true);
      setIsVideosInited(true);
      setIsLoadingPage(false);
      setIsDisabled(false);
    }
  }, [languageId]);

  async function initPageFiles(pageId) {
    setIsLoadingFiles(true);
    const tmpFiles = await GetPageFiles(pageId);
    console.log({ tmpFiles });
    setFiles(tmpFiles && tmpFiles.length > 0 ? [...tmpFiles] : []);
    setIsLoadingFiles(false);
  }

  async function initPageVideos(pageId) {
    if (!isVideosInited) {
      setIsVideosInited(true);
      setIsLoadingVideos(true);
      const tmpVideos = await GetPageVideos(pageId);
      console.log({ tmpVideos });
      setVideos(tmpVideos && tmpVideos.length > 0 ? [...tmpVideos] : []);
      setIsLoadingVideos(false);
    }
  }
  function onLocationChange(newParent) {
    setIsChangeLocationState(false);
    console.log(pageValues.topLevelId);
    if (newParent) {
      const trailTmp = getTrailData(newParent.levelId, projectParents);
      if (trailTmp && trailTmp.length > 0) {
        const trailRev = trailTmp.reverse();
        setTrail(trailRev);
        console.log({ trailRev });
      }
      pageValues.topLevelId = newParent.levelId;
      setPageValues(pageValues);
      console.log(newParent.levelId);
    }
  }

  async function Delete() {}
  async function OnValidSubmit() {
    setIsDisabled(true);
    const result = await UpdatePage(pageValues);
    history.push("/pages");
  }
  async function OnDownloadTypeChange(fileId, fileActiion) {
    await UpdateFileAction(fileId, fileActiion == 1);
  }

  function onSelectedImage(id) {
    setIsImageModalActive(false);
    pageValues.backgroundImageId = id;
    if (pageValues.id) {
      SetPage(pageValues);
      const newp = GetPage(levelId, topLevelId, languageId);
      setPageValues(newp);
      console.log({ newp });
    }
  }

  async function onFileRemove(fileId) {
    setDeletingFileId(fileId);
    await DeleteFile(fileId, pageValues.levelId);
    const indexToRemove = files.findIndex((obj) => obj.id === fileId);
    if (indexToRemove !== -1) {
      // Remove the object from the array
      const newArray = [...files];
      newArray.splice(indexToRemove, 1);
      // Update the state with the modified array
      setFiles(newArray);
      setDeletingFileId(null);
    }
  }

  function onFileSuccessUpload(fileId) {
    setNewFileIdState(null);
    if (fileId) {
      const tmpFiles = files;
      tmpFiles.push({ id: fileId, action: 0 });
      setFiles([...tmpFiles]);
    }
  }

  return (
    <>
      <div className="container-fluid p-0">
        {isChangeLocationState && (
          <Modal
            title="Parent selection"
            show={isChangeLocationState}
            onHide={(e) => setIsChangeLocationState(false)}
            fullscreen={false}
            hasCloseButton={true}
          >
            <ParentsList
              parents={
                projectParents && projectParents.length > 0
                  ? projectParents
                  : []
              }
              onSelected={onLocationChange}
              projectPagesBasic={projectPagesBasic}
            />
          </Modal>
        )}
        {isImageModalActive && (
          <Modal
            title="Main image selection"
            show={isImageModalActive}
            onHide={(e) => setIsImageModalActive(false)}
            fullscreen={true}
            hasCloseButton={true}
          >
            <ImageStorage
              onSelectedImage={onSelectedImage}
              projectImages={projectImages}
              setProjectImages={setProjectImages}
              isLoading={isLoading}
            />
          </Modal>
        )}
        <div className="row mt-4">
          {isLoadingPage ? (
            <>
              <Skeleton />
            </>
          ) : (
            <>
              <div className="col-lg-8 mb-3">
                <div className="card">
                  <div className="card-header p-3 pt-2">
                    <div className="row">
                      <div className="col-6 d-flex align-items-center">
                        <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                          <i className="material-icons opacity-10">
                            <i className="bi bi-file-earmark-plus app-icon"></i>
                          </i>
                        </div>
                        <h6 className="mb-0">
                          {pageValues && pageValues.id
                            ? "Edit page"
                            : "New Page"}
                        </h6>
                      </div>
                      <div className="col-6 text-end">
                        {pageValues && (
                          <>
                            <button
                              className="btn btn-sm btn-primary m-sm-0 me-sm-3"
                              disabled={isDisabled}
                              onClick={(e) => OnValidSubmit()}
                            >
                              {pageValues.id ? "Update page" : "Add new page"}
                            </button>
                            {pageValues.id && (
                              <button
                                className="btn btn-sm btn-danger m-0"
                                disabled={isDisabled}
                                onClick={(e) => Delete()}
                              >
                                Delete
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-2">
                    <div className="" id="mpage">
                      <div className="" id="rteSection">
                        {/* @if (!string.IsNullOrEmpty(Errors))
                                {
                                    <div className="row col-12 text-center">
                                        <p>@Errors</p>
                                    </div>
                                } */}
                        {trail && trail.length > 0 && (
                          <div className="form-group">
                            <span className="fw-bold">
                              Location:
                              {pageValues && pageValues.id && (
                                <button
                                  className="btn btn-xsm bg-gradient-dark m-0 ms-2 mb-2"
                                  disabled={isDisabled}
                                  onClick={(e) =>
                                    setIsChangeLocationState(true)
                                  }
                                >
                                  Change
                                </button>
                              )}
                            </span>
                            <span className="me-2">
                              <Breadcrumb>
                                {trail.map((p, index) => {
                                  return (
                                    <>
                                      <Breadcrumb.Item onClick={(e) => {}}>
                                        {p.name}
                                      </Breadcrumb.Item>
                                    </>
                                  );
                                })}
                              </Breadcrumb>
                            </span>
                          </div>
                        )}
                        <div className="form-group">
                          <Input
                            placeholder="Title"
                            value={pageValues ? pageValues.title : ""}
                            onChange={(e) => {
                              pageValues.title = e.target.value;
                              SetPage(pageValues);
                              const newp = GetPage(
                                levelId,
                                topLevelId,
                                languageId
                              );
                              setPageValues(newp);
                            }}
                          />
                          {/* @if (isSubmitClicked && isTitleError)
                                    {
                                        <div className="validation-message">The title field is required.</div>
                                    } */}
                        </div>
                        <div className="form-group">
                          <Input
                            placeholder="Description"
                            value={pageValues ? pageValues.description : ""}
                            onChange={(e) => {
                              pageValues.description = e.target.value;
                              SetPage(pageValues);
                              const newp = GetPage(
                                levelId,
                                topLevelId,
                                languageId
                              );
                              setPageValues(newp);
                            }}
                          />
                        </div>

                        <div className="form-group">
                          {addableLanguages && addableLanguages.length > 0 ? (
                            <>
                              <label className="font-weight-bold">
                                Language
                              </label>
                              <div>
                                <ComboBox
                                  dataSource={addableLanguages}
                                  onChange={(e) => {
                                    const tmpPageValues = pageValues;
                                    tmpPageValues.languageId = e;
                                    setPageValues(tmpPageValues);
                                  }}
                                  defaultValue={
                                    pageValues ? pageValues.languageId : ""
                                  }
                                />
                              </div>
                              {/* <div className="validation-message">
                            The language field is required.
                          </div> */}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="form-group">
                          <Input
                            placeholder="Created by"
                            value={pageValues ? pageValues.createBy : ""}
                            onChange={(e) => {
                              const tmpPageValues = pageValues;
                              tmpPageValues.createBy = e.target.value;
                              setPageValues(tmpPageValues);
                            }}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label className="font-weight-bold me-3">
                            Text
                            <Toggle
                              className="ms-3"
                              checked="Show"
                              unChecked="Hide"
                              defaultChecked={true}
                              onChange={(e) => {
                                setShowHideRichTextEditor(
                                  !showHideRichTextEditor
                                );
                              }}
                            />
                          </label>
                          {showHideRichTextEditor && (
                            <div>
                              <Richtext
                                onChange={(e) => {
                                  pageValues.text = e.target.value;
                                  SetPage(pageValues);
                                  const newp = GetPage(
                                    levelId,
                                    topLevelId,
                                    languageId
                                  );
                                  setPageValues(newp);
                                }}
                                data={pageValues ? pageValues.text : ""}
                              />
                              {/* <div
                            dangerouslySetInnerHTML={{
                              __html: pageValues ? pageValues.text : "",
                            }}
                          /> */}
                              {/* <textarea>{pageValues.text}</textarea> */}
                            </div>
                          )}
                        </div>
                        {/* @if (Tools != null)
                                {
                                    <div className="form-group mt-2">
                                        <label className="font-weight-bold me-3">
                                            Text
                                            <button className="btn btn-xsm bg-gradient-dark m-0" @onClick="@(e=> ShowHideRichTextEditor())">@(showHideRichTextEditor ? "Hide" : "Show")</button>
                                        </label>
                                        @if (showHideRichTextEditor)
                                        {
                                            <SfRichTextEditor @ref="rteObj" ID="mytext" EnableResize="true" ShowCharCount="true" MaxLength="90000" @bind-Value="@text">
                                                <RichTextEditorToolbarSettings Items="@Tools">
                                                    <RichTextEditorCustomToolbarItems>
                                                        <RichTextEditorCustomToolbarItem Name="Symbol">
                                                            <Template>
                                                                <button className="e-btn e-tbar-btn" @onClick="@(e=> GlowClicked(GlowType.light))">
                                                                    <div className="e-tbar-btn-text" style="font-weight: 500;">Glow light</div>
                                                                </button>
                                                            </Template>
                                                        </RichTextEditorCustomToolbarItem>
                                                        <RichTextEditorCustomToolbarItem Name="Symbol1">
                                                            <Template>
                                                                <button className="e-btn e-tbar-btn" @onClick="@(e=> GlowClicked(GlowType.dark))">
                                                                    <div className="e-tbar-btn-text" style="font-weight: 500;">Glow dark</div>
                                                                </button>
                                                            </Template>
                                                        </RichTextEditorCustomToolbarItem>
                                                        <RichTextEditorCustomToolbarItem Name="Symbol2">
                                                            <Template>
                                                                <SfButton className="e-tbar-btn" @onClick="OpenImageStockDialog">
                                                                    <div className="e-tbar-btn-text" style="font-weight: 500;">Upload Image</div>
                                                                </SfButton>
                                                            </Template>
                                                        </RichTextEditorCustomToolbarItem>
                                                    </RichTextEditorCustomToolbarItems>
                                                </RichTextEditorToolbarSettings>
                                                <RichTextEditorPasteCleanupSettings Prompt="false" KeepFormat="false" />
                                                <RichTextEditorQuickToolbarSettings Image="@ImageTools" />
                                            </SfRichTextEditor>
                                        }
                                    </div>
                                } */}
                      </div>
                    </div>
                    {/* @if (dialogVisible)
                        {
                            <SfDialog @bind-Visible="@dialogVisible" ZIndex="1000" ShowCloseIcon="true" AllowDragging="true" EnableResize="true" IsModal="true" Width="100%" Height="50%" Target="#main-container">
                                <DialogTemplates>
                                    <Header> Special Characters </Header>
                                    <Content>
                                        <ImageUploader ProjectId="@projectId" OnImageSelected="ImageSelected" />
                                    </Content>
                                </DialogTemplates>
                                <DialogButtons>
                                    <DialogButton Content="Insert" IsPrimary="true" onClick="OnInsert" Disabled="@isDisabled" />
                                    <DialogButton Content="Cancel" onClick="DialogOverlay" />
                                </DialogButtons>
                                <DialogAnimationSettings Effect="DialogEffect.Zoom"></DialogAnimationSettings>
                            </SfDialog>
                        } */}
                    {/* @if (mainImageDialogVisible)
                        {
                            <SfDialog @bind-Visible="@mainImageDialogVisible" ZIndex="1000" ShowCloseIcon="true"
                                      AllowDragging="true" EnableResize="true" IsModal="true" Width="100%" Height="auto" Target="#main-container">
                                <DialogTemplates>
                                    <Header>Main image selection</Header>
                                    <Content>
                                        <ImageUploader @ref="imageUploader" ProjectId="@projectId" OnImageSelected="MainImageSelected" />
                                    </Content>
                                </DialogTemplates>
                                <DialogButtons>
                                    <DialogButton Content="Insert" IsPrimary="true" onClick="@(e=> mainImageDialogVisible = false)" Disabled="@isDisabled" />
                                    <DialogButton Content="Cancel" onClick="@(e=> mainImageDialogVisible = false)" />
                                </DialogButtons>
                                <DialogEvents OnOverlayModalClick="@(e=> mainImageDialogVisible = false)" />
                                <DialogAnimationSettings Effect="DialogEffect.Zoom"></DialogAnimationSettings>
                            </SfDialog>
                        } */}
                  </div>
                  {/* // else // <Loading /> */}
                </div>
              </div>
              <div className="col-lg-4">
                {pageSupportLanguages && pageSupportLanguages.length > 0 && (
                  <div className="card mb-3">
                    <div className="card-header pb-0 p-3">
                      <label className="m-0 me-3">
                        Available with the following languages:
                      </label>
                    </div>
                    <div className="card-body p-3">
                      {pageSupportLanguages.map((x) => {
                        return (
                          <Link
                            key={uuidv4()}
                            to={`/page-wizard/${x.levelId}/${topLevelId}/${x.languageId}`}
                          >
                            <span className="badge bg-gradient-dark aclickable me-3">
                              {x.languageId}
                            </span>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="card mb-3">
                  <div className="card-body p-3 pt-0">
                    <div className="form-group mt-3">
                      <div className="col-12 d-flex align-items-center">
                        <label className="m-0 me-3">Publish</label>
                        <Toggle
                          onChange={(e) => {
                            const tmpPageValues = pageValues;
                            tmpPageValues.isPublish = e;
                            setPageValues(tmpPageValues);
                          }}
                          checked="Yes"
                          unChecked="No"
                          defaultChecked={
                            pageValues ? pageValues.publishDate : false
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-3">
                  <div className="card-header pb-0 p-3">
                    <div className="row">
                      <div className="col-8 d-flex align-items-center">
                        <label className="m-0 me-3">Main image</label>
                      </div>
                      <div className="col-4 text-end">
                        {pageValues && !pageValues.backgroundImageId ? (
                          <a
                            className="btn btn-sm bg-gradient-dark aclickable"
                            onClick={(e) => setIsImageModalActive(true)}
                          >
                            Add
                          </a>
                        ) : (
                          <button
                            className="btn btn-link text-danger text-gradient px-3 mb-0 me-auto text-end float-end pb-0 pe-0"
                            onClick="(e=> RemoveImage())"
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-3">
                    <ul className="list-group">
                      {isImageLoading ? (
                        <></>
                      ) : (
                        <>
                          {pageValues && pageValues.backgroundImageId && (
                            <li className="row co-12 bg-gray-100">
                              <div className="text-center">
                                <img
                                  className="upload-image"
                                  alt="Image"
                                  src={getThumbnail(
                                    pageValues.backgroundImageId
                                  )}
                                />
                              </div>
                            </li>
                          )}
                        </>
                      )}
                    </ul>
                  </div>
                </div>

                {isLoadingVideos ? (
                  <div className="card mb-3">
                    <div className="card-header pb-0 p-3">
                      <div className="row">
                        <div className="col-8 d-flex align-items-center">
                          <label className="m-0 me-3">Loading videos..</label>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-3">
                      <Skeleton />
                    </div>
                  </div>
                ) : (
                  <div className="card mb-3">
                    <div className="card-header pb-0 p-3">
                      <div className="row">
                        <div className="col-8 d-flex align-items-center">
                          <label className="m-0 me-3">Include videos</label>
                          <Toggle
                            onChange={(e) => {
                              setPageValues((prevState) => ({
                                ...prevState,
                                isIncludesVideos: e,
                              }));
                            }}
                            checked="Yes"
                            unChecked="No"
                            defaultChecked={
                              pageValues ? pageValues.isIncludesVideos : false
                            }
                          />
                        </div>
                        {pageValues && pageValues.isIncludesVideos === true && (
                          <div className="col-4 text-end">
                            <a
                              className="btn btn-sm bg-gradient-dark aclickable"
                              onClick={(e) => {
                                setVideos((prevState) => {
                                  return [
                                    ...prevState,
                                    {
                                      pageId: pageValues
                                        ? pageValues.levelId
                                        : "",
                                      videoID: uuidv4(),
                                    },
                                  ];
                                });
                              }}
                            >
                              Add
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="card-body p-3">
                      {pageValues &&
                        pageValues.isIncludesVideos &&
                        videos &&
                        videos.length > 0 && (
                          <>
                            {videos.map((video) => {
                              return (
                                <div key={uuidv4()}>
                                  <div className="row co-12 bg-gray-100">
                                    <div>
                                      <div className="row">
                                        <div className="justify-content-between">
                                          {video && video.data && (
                                            <button
                                              className={[
                                                styles.removeBtn,
                                                "btn btn-sm btn-primary",
                                              ].join(" ")}
                                              onClick={async (e) => {
                                                await UpdateVideos(
                                                  videos,
                                                  pageValues.levelId
                                                );
                                              }}
                                            >
                                              Save
                                            </button>
                                          )}

                                          <button
                                            className={[
                                              styles.removeBtn,
                                              "btn btn-link text-danger text-gradient px-3 mb-0 pb-0 pe-0 float-end",
                                            ].join(" ")}
                                            onClick={async (e) => {
                                              if (
                                                video.videoID &&
                                                pageValues &&
                                                pageValues.levelId
                                              ) {
                                                await DeleteVideo(
                                                  video.videoID,
                                                  pageValues.levelId
                                                );
                                              }
                                              setVideos((prevState) => {
                                                // Assuming pageValues is an array of objects and itemIdToRemove is the id of the item to remove
                                                return prevState.filter(
                                                  (item) =>
                                                    item.videoID !==
                                                    video.videoID
                                                );
                                              });
                                            }}
                                          >
                                            <i className="bi bi-trash3-fill"></i>
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <Input
                                        // className="e-outline mb-2"
                                        placeholder="https://www.youtube.com/embed/ZoclmJ9UTrE"
                                        onChange={(e) => {
                                          video.data = e.target.value;
                                          const tmpVideos = videos;
                                          setVideos([...tmpVideos]);
                                        }}
                                        value={video.data ? video.data : null}
                                      />
                                      {video &&
                                        video.data &&
                                        String(video.data).startsWith(
                                          "https://www.youtube.com/embed/"
                                        ) && (
                                          <div class="iframe-container">
                                            <iframe
                                              width="560"
                                              height="315"
                                              src={video.data}
                                              frameborder="0"
                                              allow="autoplay; encrypted-media"
                                              allowfullscreen
                                            ></iframe>
                                          </div>
                                        )}
                                    </div>
                                    <hr />
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                    </div>
                  </div>
                )}

                {isLoadingFiles ? (
                  <div className="card mb-3">
                    <div className="card-header pb-0 p-3">
                      <div className="row">
                        <div className="col-8 d-flex align-items-center">
                          <label className="m-0 me-3">Loading files..</label>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-3">
                      <Skeleton />
                    </div>
                  </div>
                ) : (
                  <div className="card mb-3">
                    <div className="card-header pb-0 p-3">
                      <div className="row">
                        <div className="col-8 d-flex align-items-center">
                          <label className="m-0 me-3">Include files</label>
                          <Toggle
                            onChange={(e) => {
                              setPageValues((prevState) => ({
                                ...prevState,
                                isIncludesFiles: e,
                              }));
                            }}
                            checked="Yes"
                            unChecked="No"
                            defaultChecked={
                              pageValues ? pageValues.isIncludesFiles : false
                            }
                          />
                        </div>
                        {pageValues && pageValues.isIncludesFiles === true && (
                          <div className="col-4 text-end">
                            <button
                              className="btn btn-sm bg-gradient-dark aclickable"
                              onClick={(e) => {
                                if (!newFileIdState) {
                                  const tmpId = uuidv4();
                                  setNewFileIdState(tmpId);
                                  setFiles((prevState) => {
                                    return [
                                      ...prevState,
                                      { id: tmpId, action: 0 },
                                    ];
                                  });
                                }
                              }}
                            >
                              Add
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="card-body p-3">
                      <ul className="list-group">
                        {pageValues && pageValues.isIncludesFiles && (
                          <>
                            {files && files.length > 0 && (
                              <>
                                {pageValues.levelId && newFileIdState && (
                                  <li
                                    className="row co-12 bg-gray-100"
                                    key={uuidv4()}
                                  >
                                    <div className="justify-content-between">
                                      <button
                                        className="btn btn-link text-danger text-gradient px-3 mb-0 pb-0 pe-0 float-end"
                                        onClick={(e) => {
                                          setNewFileIdState(null);
                                        }}
                                      >
                                        <i className="bi bi-trash3-fill"></i>
                                        Remove
                                      </button>
                                    </div>
                                    <div className="">
                                      <div className="">
                                        {isFileLoading ? (
                                          <>loading..</>
                                        ) : (
                                          <>
                                            <FileUploader
                                              onRemove={onFileRemove}
                                              onSuccessUpload={
                                                onFileSuccessUpload
                                              }
                                              pageId={pageValues.levelId}
                                            />
                                          </>
                                        )}
                                        <hr />
                                      </div>
                                    </div>
                                  </li>
                                )}
                                {files.map((file) => {
                                  return (
                                    <li
                                      className="row co-12 bg-gray-100"
                                      key={uuidv4()}
                                    >
                                      <div className="justify-content-between">
                                        <button
                                          className="btn btn-link text-danger text-gradient px-3 mb-0 pb-0 pe-0 float-end"
                                          onClick={async (e) => {
                                            if (
                                              pageValues &&
                                              pageValues.levelId &&
                                              file &&
                                              file.id
                                            ) {
                                              await onFileRemove(file.id);
                                            }
                                          }}
                                        >
                                          <i className="bi bi-trash3-fill"></i>
                                          Remove
                                        </button>
                                      </div>
                                      <div className="">
                                        <div className="">
                                          {isFileLoading ? (
                                            <>loading..</>
                                          ) : (
                                            <>
                                              {deletingFileId &&
                                              deletingFileId == file.id ? (
                                                <>Deleting..</>
                                              ) : (
                                                <>
                                                  <p>
                                                    File: {file.id} has been
                                                    added/updated successfully.
                                                  </p>
                                                  {newFileIdState !=
                                                    file.id && (
                                                    <>
                                                      {changingFileIdAction ==
                                                      file.id ? (
                                                        <>Loading..</>
                                                      ) : (
                                                        <>
                                                          {" "}
                                                          <RadioButton
                                                            dataSource={[
                                                              {
                                                                label:
                                                                  "Download only",
                                                                value: 0,
                                                              },
                                                              {
                                                                label:
                                                                  "Download and view",
                                                                value: 1,
                                                              },
                                                            ]}
                                                            defaultValue={
                                                              file.action
                                                            }
                                                            onChange={async ({
                                                              target: { value },
                                                            }) => {
                                                              setChangingFileIdAction(
                                                                file.id
                                                              );
                                                              file.action =
                                                                value;
                                                              setFiles([
                                                                ...files,
                                                              ]);
                                                              await OnDownloadTypeChange(
                                                                file.id,
                                                                value
                                                              );
                                                              setChangingFileIdAction(
                                                                null
                                                              );
                                                            }}
                                                          />
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                          <hr />
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </>
                            )}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default PageWizard;
