import React, { useState, useEffect, useRef } from "react";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { v4 as uuidv4 } from "uuid";

const Input = ({
  className = "mb-3",
  placeholder,
  value,
  name,
  onChange,
  type = "text",
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsFocus(false);
        if (value === "") {
          // Remove class when the input is empty and loses focus
          // alert("Input is empty");
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [value]);

  const handleBlur = () => {
    setIsFocus(false);
    if (value === "") {
      // Remove class when the input is empty and loses focus
    }
    console.log({ isFocus });
  };

  const handleFocus = () => {
    setIsFocus(true);
  };
  const handleChange = (event) => {
    if (event.target.value !== "") {
      // Remove class when there is a value in the input
      setIsFocus(false);
    } else {
      setIsFocus(true);
    }
    console.log({ isFocus });
  };
  return (
    <>
      <input
        // onFocus={handleFocus}
        // onBlur={handleBlur}
        placeholder={placeholder}
        type={type}
        className="form-control"
        onChange={onChange}
        name={name}
        value={value}
        ref={inputRef}
      />
      {/* <div
        key={uuidv4()}
        className={[
          "input-group input-group-outline",
          className,
          isFocus ? "is-focused" : "",
        ].join(" ")}
      >
        <label className="form-label">{placeholder}</label>
        <input
          onFocus={handleFocus}
          // onBlur={handleBlur}
          type={type}
          className="form-control"
          onChange={onChange}
          name={name}
          ref={inputRef}
          value={value}
        />
        {value ? (
          <input
            // onFocus={handleFocus}
            // onBlur={handleBlur}
            type={type}
            className="form-control"
            onChange={onChange}
            name={name}
            value={value}
            ref={inputRef}
          />
        ) : (
          <input
            // onFocus={handleFocus}
            // onBlur={handleBlur}
            type={type}
            className="form-control"
            onChange={onChange}
            name={name}
            ref={inputRef}
          />
        )}
      </div> */}
    </>
  );
};
export default Input;
