import React, { useState, useEffect } from "react";
import { Skeleton } from "antd";
import { useParams } from "react-router-dom";
import PageFilter from "../Pages/PageFilter";
import AppPagination from "../../components/Basic/AppPagination";
import { Form } from "react-bootstrap";
import {
  findSubtree,
  findSubtrees,
  generateTitleTree,
} from "../../helpers/GlobalHelper";
import Breadcrumb from "react-bootstrap/Breadcrumb";

const Parents = ({ isLoading, projectPagesBasic, projectParents }) => {
  const { levelId, topLevelId, languageId } = useParams();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [items, setItems] = useState([]);
  const [isLoadingPagin, setIsLoadingPagin] = useState(true);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(Math.ceil(total / itemsPerPage));
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [tree, setTree] = useState(null);
  const [path, setPath] = useState([]);
  const [selectedParent, setSelectedParent] = useState(null);
  const [showBackButton, setShowBackButton] = useState(false);
  const [previousParent, setPreviousParent] = useState(null);
  const [originalTree, setOriginalTree] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      const treeTmp = generateTitleTree(projectParents, false);
      setOriginalTree(treeTmp);
      setTree(treeTmp);
      console.log({ treeTmp });
      setItems(treeTmp ? treeTmp : []);
      setTotal(treeTmp ? treeTmp.length : 0);
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setPageCount(Math.ceil((treeTmp ? treeTmp.length : 0) / itemsPerPage));
      setIsLoadingPagin(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      console.log("running total effect");
      setIsLoadingPagin(true);
      setTotalPages(Math.ceil((items ? items.length : 0) / itemsPerPage));
      setIsLoadingPagin(false);

      // console.log("initiging");
      // setTotal(currentItems ? currentItems.length : 0);
      // setPageCount(Math.ceil(currentItems.length / itemsPerPage));
      // setCurrentPage(1);
      // setCurrentItems(currentItems.slice(0, itemsPerPage));
    }
  }, [items]);

  function onPageItemsChange(value) {
    setItemsPerPage(parseInt(value));
    setPageCount(1);
  }
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Update currentPage here
  };

  function onSelectChange(parent) {
    setSelectedParent(parent);
    setPreviousParent(parent);
    if (parent && !path.some((x) => x.levelId == parent.levelId)) {
      //check if found already by same parentid
      const sameParentIndex = path.findIndex(
        (x) => x.topLevelId == parent.topLevelId
      );
      if (sameParentIndex == -1) {
        path.push(parent);
        setPath(path);
      } else {
        path.splice(sameParentIndex, 1); // Remove 1 element at the specified index
        path.push(parent);
        setPath(path);
      }
    }
    console.log({ path });

    const tmpList =
      projectParents && projectParents.length > 0
        ? projectParents
            .filter((x) => x.topLevelId == parent.levelId)
            .sort((a, b) => a.order - b.order)
        : [];
    console.log({ tmpList });
    const tmpTree = generateTitleTree(tmpList, false);
    console.log({ tmpTree });
    setItems(parent.Child);
  }

  function onFilterChange(filter) {
    setIsLoadingPagin(true);

    let tmpList = projectParents;
    console.log(filter);
    if (filter) {
      handlePageChange(1);
      if (filter.name) {
        tmpList = tmpList.filter(
          (x) =>
            x.name && x.name.toLowerCase().includes(filter.name.toLowerCase())
        );
      }

      if (filter.status) {
        if (filter.status == 0) {
          tmpList = tmpList.filter((x) => x.publishDate);
        }
        if (filter.status == 1) {
          tmpList = tmpList.filter((x) => x.isProtectedByPassword);
        }
        if (filter.status == 2) {
          tmpList = tmpList.filter((x) => x.draftDate);
        }
      }
      if (tmpList && tmpList.length > 0) {
        const foundData = findSubtrees(
          tree,
          tmpList.map((x) => x.levelId)
        );
        setTotalPages(
          Math.ceil((foundData ? foundData.length : 0) / itemsPerPage)
        );
        setItems(foundData);
      }
    } else {
      setItems(tree);
      setTotalPages(Math.ceil((tree ? tree.length : 0) / itemsPerPage));
    }
    setIsLoadingPagin(false);
  }

  return (
    <>
      {" "}
      {isLoading ? (
        <>
          <Skeleton />
        </>
      ) : (
        <>
          {" "}
          <div className="row">
            <PageFilter
              projectPagesBasic={projectPagesBasic}
              onFilterChange={onFilterChange}
              parents={projectParents}
              selectOnlyParentWithChild={true}
              className="mb-3"
              displayByLocation={false}
            />
          </div>
          {items && items.length > 0 && items.some((x) => x.topLevelId) && (
            <div className="row">
              <div className="col">
                <button
                  className="fw-bold btn btn-sm bg-gradient-dark"
                  onClick={(e) => {
                    console.log({ selectedParent });
                    if (path && path.length > 0) {
                      // Check if data is not null and has items
                      const newData = path.slice(0, -1);
                      setPath(newData);
                    }
                    if (selectedParent) {
                      console.log({ selectedParent });
                    }
                    console.log({ originalTree });
                    console.log({ previousParent });
                    if (previousParent) {
                      if (!previousParent.topLevelId) {
                        setItems(originalTree);
                      } else {
                        if (originalTree && originalTree.length > 0) {
                          const foundParent = findSubtree(
                            originalTree,
                            previousParent.levelId
                          );
                          console.log({ foundParent });
                          //get all parents
                          const foundParentParent = findSubtree(
                            originalTree,
                            foundParent.topLevelId
                          );
                          console.log({ foundParentParent });
                          if (foundParentParent) {
                            setItems(foundParentParent.Child);
                            setPreviousParent(foundParentParent);
                            //   if (foundParentParent.topLevelId) {
                            //     setItems(foundParentParent.Child);
                            //   } else {
                            //     setItems(originalTree);
                            //   }
                          }
                        }
                      }
                    }
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          )}
          <div className="row">
            <p>
              {path && path.length > 0 && (
                <>
                  <Breadcrumb>
                    {path.map((p, index) => {
                      return (
                        <>
                          <Breadcrumb.Item
                            onClick={(e) => {
                              const foundParent = findSubtree(
                                originalTree,
                                p.levelId
                              );

                              if (foundParent) {
                                const newData = path.filter(
                                  (item, i) => i <= index
                                );
                                setPath(newData);
                                setItems(foundParent.Child);
                                setPreviousParent(foundParent);
                              }
                            }}
                          >
                            {p.name}
                          </Breadcrumb.Item>
                        </>
                      );
                    })}
                  </Breadcrumb>
                </>
              )}
            </p>
          </div>
          <div className="back-white border-r-3 card mt-3 h-max">
            <div class="table-responsive">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th class="align-middle text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Order
                    </th>
                    <th class="align-middle text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Name
                    </th>
                    <th class="align-middle text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Languages
                    </th>
                    <th class="align-middle text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Sub Category
                    </th>
                    <th class="align-middle text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Pages
                    </th>
                    <th class="text-secondary opacity-7"></th>
                  </tr>
                </thead>
                <tbody>
                  {items && items.length > 0 ? (
                    <>
                      {items
                        .slice(
                          (currentPage - 1) * itemsPerPage,
                          (currentPage - 1) * itemsPerPage + itemsPerPage
                        )
                        .map((parent) => {
                          return (
                            <tr>
                              <td>
                                {parent.HasChild ? (
                                  <button
                                    className="btn btn-sm bg-gradient-dark"
                                    onClick={(e) => onSelectChange(parent)}
                                  >
                                    View sub titles
                                  </button>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td className="align-middle text-center text-sm">
                                {parent.order}
                              </td>
                              <td className="align-middle text-center text-sm">
                                {parent.name}
                              </td>
                              <td className="align-middle text-center text-sm">
                                {parent.languageId}
                              </td>
                              <td className="align-middle text-center text-sm">
                                {parent.Child ? parent.Child.length : 0}
                              </td>
                              <td className="align-middle text-center text-sm">
                                {parent.pages}
                              </td>
                              <td>
                                <div>
                                  <a
                                    class="btn btn-link text-gradient-dark ps-0 pe-0 mb-0 me-3"
                                    onclick="@(e=> {
    PreEditCategory(title);
})"
                                  >
                                    <i class="bi bi-pencil-square font-xl app-icon"></i>
                                  </a>
                                  <a
                                    class="btn btn-xsm bg-gradient-dark"
                                    onclick="@(e=> pageCategoryMethods.OpenOnSelectLocationDialog(title))"
                                  >
                                    Move
                                  </a>
                                  <a class="btn btn-link text-dark ps-0 pe-0 me-3 cursor-disable">
                                    <i class="bi bi-trash3-fill font-xl app-icon"></i>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
              <div className="mt-3">
                <div className="row">
                  <div className="col">
                    {items && items.length > itemsPerPage && (
                      <>
                        {isLoadingPagin ? (
                          <>loading..</>
                        ) : (
                          <AppPagination
                            total={total}
                            itemsPerPage={itemsPerPage}
                            onPageChange={handlePageChange}
                            totalPages={totalPages}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div className="col">
                    <div>
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Control
                          as="select"
                          defaultValue="5"
                          onChange={(e) => {
                            console.log(e.target.value);
                            onPageItemsChange(e.target.value);
                          }}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col">
                    <span className="fw-bold mt-3">
                      Total:
                      {items ? items.length : 0}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Parents;
