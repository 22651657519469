import React, { useState, useEffect } from "react";
import Layout from "./components/Layout/Layout";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Home from "./pages/Home/Home";
import PageWizard from "./pages/PageWizard/PageWizard";
import {
  GetProjectLanguages,
  GetAllProjectPages,
  GetAllProjectParents,
  GetAllProjectPagesBasic,
  GetProjectImages,
} from "./services/apiService";
import Pages from "./pages/Pages/Pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Parents from "./pages/Parents/Parents";
import { useAuth, hasAuthParams } from "react-oidc-context";

function App() {
  // const oidcConfig = {
  //   onSignIn: (user) => {
  //     debugger;
  //     if (!user) {
  //       setIsAuthorized(false);
  //     } else {
  //       if (user.access_token) {
  //         localStorage.setItem("access_token", user.access_token);
  //         localStorage.setItem("userData", JSON.stringify(user.profile));
  //         setUser(user);
  //         setIsAuthorized(true);
  //         // history.push("/");
  //         window.location.href = "/"; // Redirect to the root URL
  //         // setIsAuthorized(true);
  //       } else {
  //         setIsAuthorized(false);
  //       }
  //     }
  //   },
  //   authority: process.env.REACT_APP_IDENTITY_AUTHORITY, //"https://localhost:44395", // Replace with your OpenNicDT authority URL
  //   clientId: process.env.REACT_APP_IDENTITY_CLIENTID, //"omadot_dash_app4", // Replace with your client ID
  //   redirectUri: process.env.REACT_APP_IDENTITY_REDIRECTURL, //"http://localhost:3000/login", // Replace with your redirect URI
  //   scope: process.env.REACT_APP_IDENTITY_SCOPE, //"offline_access omadot_api_scope", //(string, default: 'openid'): The scope being requested from the OIDC provider.
  // };

  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "/public/assets/js/material-dashboard.js";
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []); // Re-run this hook if the url changes
  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    });
  }, [auth.events, auth.signinSilent]);

  // automatically sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);
  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <AuthorizedContext
        access_token={auth.user?.access_token}
        profile={auth.user?.profile}
        auth={auth}
      />
    );
  }
  return <button onClick={() => void auth.signinRedirect()}>Log in</button>;

  // function SetPage(page) {
  //   console.log(page);
  //   if (page && projectPagesBasic && projectPagesBasic.length > 0) {
  //     const targetObjectIndex = projectPagesBasic.findIndex(
  //       (obj) => obj.title.levelId == page.topLevelId
  //     );

  //     // Check if the object with id = 2 exists
  //     if (targetObjectIndex !== -1) {
  //       const updatedObject = { ...projectPagesBasic[targetObjectIndex] };

  //       // Find the page with id = 3 within the Pages array of the target object
  //       const targetPageIndex = updatedObject.pages.findIndex(
  //         (page) => page.languageId == page.languageId
  //       );

  //       // Check if the page with id = 3 exists within the target object
  //       if (targetPageIndex !== -1) {
  //         // Create a shallow copy of the target page
  //         const updatedPage = { ...updatedObject.pages[targetPageIndex] };

  //         // Replace the old page with the updated page
  //         updatedObject.pages[targetPageIndex] = updatedPage;

  //         // Replace the old object with the updated object
  //         const updatedObjectsList = [...projectPagesBasic];
  //         updatedObjectsList[targetObjectIndex] = updatedObject;

  //         // Update the state or variable holding the objects list with the updated list
  //         setProjectPagesBasic([...updatedObjectsList]); // Assuming you are using useState hook or similar
  //         // Log a message to indicate that the page has been updated
  //         console.log(GetPage(page.levelId, page.topLevelId, page.languageId));
  //       }
  //     }
  //   }
  // }
}

const AuthorizedContext = ({ access_token, profile, auth }) => {
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  useEffect(() => {
    if (!isAuthorizing) {
      localStorage.setItem("access_token", access_token);
      if (profile) {
        localStorage.setItem("userData", JSON.stringify(profile));
      }
      setIsAuthorizing(true);
    }
  }, []);
  return (
    <div>
      <Router>
        <Switch>{!isAuthorizing ? <>Authorization..</> : <Page />}</Switch>
      </Router>
    </div>
  );
};

const SigninOidc = () => {
  const history = useHistory();
  useEffect(() => {
    history.push("/");
  }, []);
  return <>Setting up your account..</>;
};

const Page = () => {
  const [projectLanguages, setProjectLanguages] = useState(null);
  const [projectParents, setProjectParents] = useState(null);
  const [projectPages, setProjectPages] = useState(null);
  const [projectPagesBasic, setProjectPagesBasic] = useState(null);
  const [projectImages, setProjectImages] = useState(null);

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!projectLanguages) {
          const response = await GetProjectLanguages();
          setProjectLanguages(response);
        }

        // if (!projectPages) {
        //   const response = await GetAllProjectPages();
        //   setProjectPages(response);
        // }

        if (!projectPagesBasic) {
          const response = await GetAllProjectPagesBasic();
          setProjectPagesBasic(response);
        }
        if (!projectParents) {
          const response = await GetAllProjectParents();
          setProjectParents(response);
        }

        setIsLoading(false);

        if (!projectImages) {
          const response = await GetProjectImages();
          setProjectImages(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    fetchData(); // Call the fetchData function when the component mounts
  }, []); // The empty dependency array ensures this effect runs only once, when the component mounts

  function GetPage(levelId, parentId, languageId) {
    if (projectPagesBasic && projectPagesBasic.length > 0) {
      const foundParent = projectPagesBasic.find(
        (x) => x.title.levelId == parentId
      );
      if (foundParent && foundParent.pages.length > 0) {
        return foundParent.pages.find(
          (x) => x.levelId == levelId && x.languageId == languageId
        );
      }
    }
  }
  function GetPageBasic(parentId) {
    if (projectPagesBasic && projectPagesBasic.length > 0) {
      const foundParent = projectPagesBasic.find(
        (x) => x.title.levelId == parentId
      );
      return foundParent;
    }
  }
  function SetPage(page) {
    if (page && projectPagesBasic && projectPagesBasic.length > 0) {
      const targetObjectIndex = projectPagesBasic.findIndex(
        (obj) => obj.title.levelId === page.topLevelId
      );

      if (targetObjectIndex !== -1) {
        const updatedObjectsList = [...projectPagesBasic];
        const updatedObject = { ...updatedObjectsList[targetObjectIndex] };

        const targetPageIndex = updatedObject.pages.findIndex(
          (pg) => pg.languageId === page.languageId
        );

        if (targetPageIndex !== -1) {
          const updatedPage = { ...updatedObject.pages[targetPageIndex] };
          // Update properties of updatedPage as needed

          updatedObject.pages[targetPageIndex] = updatedPage;
          updatedObjectsList[targetObjectIndex] = updatedObject;

          setProjectPagesBasic(updatedObjectsList);
          console.log(GetPage(page.levelId, page.topLevelId, page.languageId));
        }
      }
    }
  }

  return (
    <Layout>
      <ToastContainer />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/signin-oidc" component={SigninOidc} />
      <Route
        exact
        path="/pages"
        render={(props) => (
          <Pages
            {...props}
            projectPages={projectPages}
            projectPagesBasic={projectPagesBasic}
            isLoading={isLoading}
            projectParents={projectParents}
          />
        )}
      />
      <Route
        exact
        path="/page-wizard/:levelId?/:topLevelId?/:languageId?"
        render={(props) => (
          <PageWizard
            {...props}
            projectLanguages={projectLanguages}
            projectPagesBasic={projectPagesBasic}
            projectParents={projectParents}
            setProjectPagesBasic={setProjectPagesBasic}
            SetPage={SetPage}
            GetPage={GetPage}
            GetPageBasic={GetPageBasic}
            projectImages={projectImages}
            setProjectImages={setProjectImages}
            isLoading={isLoading}
          />
        )}
      />
      <Route
        exact
        path="/titles/:levelId?/:topLevelId?/:languageId?"
        render={(props) => (
          <Parents
            {...props}
            projectPages={projectPages}
            projectPagesBasic={projectPagesBasic}
            isLoading={isLoading}
            projectParents={projectParents}
          />
        )}
      />
      <Route exact path="/" render={(props) => <Home {...props} />} />
      {/* <Route path="/about" component={About} /> */}
      {/* Add other routes as needed */}
    </Layout>
  );
};

export default App;
