import { Get, Put, Post, Delete } from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const getApiEndpoint = () => {
  return apiEndpoint;
};
function GenerateUrl(url) {
  return `${apiEndpoint}${url}`;
}

export async function GetProjectLanguages() {
  return await Get(GenerateUrl("project/languages"));
}

export async function GetAllProjectPages() {
  return await Get(GenerateUrl("page/all"));
}

export async function GetAllProjectPagesBasic() {
  return await Get(GenerateUrl("page/parent/all/basic"));
}

export async function GetAllProjectParents() {
  return await Get(GenerateUrl("page/parent/all"));
}

export async function UpdatePage(page) {
  return await Put(GenerateUrl("page"), page);
}

export async function GetPageFiles(pageId) {
  return await Get(GenerateUrl(`page/${pageId}/files`));
}

export async function GetPageVideos(pageId) {
  return await Get(GenerateUrl(`page/${pageId}/videos`));
}

export async function GetProjectImages() {
  return await Get(GenerateUrl(`image/project`));
}

export async function DeleteImage(imageId) {
  return await Delete(GenerateUrl(`image/${imageId}`));
}

export async function DeleteFile(fileId, pageId) {
  return await Delete(GenerateUrl(`file/${fileId}/page/${pageId}`));
}

export async function DeleteVideo(videoId, pageId) {
  return await Delete(GenerateUrl(`page/${pageId}/video/${videoId}`));
}

export async function UpdateVideos(videos, pageId) {
  return await Put(GenerateUrl(`page/${pageId}/video/list`), videos);
}

export async function UpdateFileAction(fileId, action) {
  return await Put(GenerateUrl(`file/${fileId}/action/${action}`));
}
