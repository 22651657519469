import ListGroup from "react-bootstrap/ListGroup";
import React, { useState, useEffect } from "react";
import "./ParentsList.css";
import Badge from "react-bootstrap/Badge";
import { parseHtml } from "@syncfusion/ej2-react-richtexteditor";
import Breadcrumb from "react-bootstrap/Breadcrumb";

const ParentsList = ({
  projectPagesBasic,
  parents,
  onSelected,
  selectOnlyParentWithChild = false,
}) => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedParent, setSelectedParent] = useState(null);
  const [showBackButton, setShowBackButton] = useState(false);
  const [isSelectChanged, setiISelectChanged] = useState(null);
  const [path, setPath] = useState([]);

  useEffect(() => {
    // Actions to perform after state update
    if (isSelectChanged) {
      setiISelectChanged(null);
      setItem(isSelectChanged);
    }
    console.log(selectedParent);
  }, [isSelectChanged]); // Add selectedParent to the dependency array

  useEffect(() => {
    if (isLoading) {
      if (selectOnlyParentWithChild) {
        setList(
          parents && parents.length > 0
            ? parents
                .filter((x) => !x.topLevelId && x.type == 0)
                .sort((a, b) => a.order - b.order)
            : []
        );
      } else {
        setList(
          parents && parents.length > 0
            ? parents
                .filter((x) => !x.topLevelId)
                .sort((a, b) => a.order - b.order)
            : []
        );
      }

      setIsLoading(false);
    }
  }, []);

  function setItem(parent) {
    setSelectedParent(parent);

    if (parent && !path.some((x) => x.levelId == parent.levelId)) {
      //check if found already by same parentid
      const sameParentIndex = path.findIndex(
        (x) => x.topLevelId == parent.topLevelId
      );
      if (sameParentIndex == -1) {
        path.push(parent);
        setPath(path);
      } else {
        path.splice(sameParentIndex, 1); // Remove 1 element at the specified index
        path.push(parent);
        setPath(path);
      }
    }
    if (
      selectOnlyParentWithChild &&
      (parents
        ? parents.filter((x) => x.type == 1 && x.topLevelId == parent.levelId)
            .length > 0
        : false)
    ) {
      if (
        parents
          ? parents.filter((x) => x.type == 0 && x.topLevelId == parent.levelId)
              .length > 0
          : false
      ) {
        setList(
          parents && parents.length > 0
            ? parents
                .filter((x) => x.topLevelId == parent.levelId)
                .sort((a, b) => a.order - b.order)
            : []
        );
      }
    } else {
      const foundChild =
        parents && parents.filter((x) => x.topLevelId == parent.levelId);
      if (foundChild && foundChild.length > 0) {
        setList(
          parents && parents.length > 0
            ? parents
                .filter((x) => x.topLevelId == parent.levelId)
                .sort((a, b) => a.order - b.order)
            : []
        );
        setShowBackButton(true);
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <>loading..</>
      ) : (
        <>
          {list && list.length > 0 ? (
            <>
              <p>
                {path && path.length > 0 && (
                  <>
                    <Breadcrumb>
                      {path.map((p, index) => {
                        return (
                          <>
                            <Breadcrumb.Item
                              onClick={(e) => {
                                const foundParent =
                                  parents && parents.length > 0
                                    ? parents.find(
                                        (x) => x.levelId == p.levelId
                                      )
                                    : null;
                                if (foundParent) {
                                  setSelectedParent(foundParent);
                                  const newData = path.filter(
                                    (item, i) => i <= index
                                  );
                                  setPath(newData);
                                  setList(
                                    parents && parents.length > 0
                                      ? parents
                                          .filter(
                                            (x) =>
                                              x.topLevelId ==
                                              foundParent.levelId
                                          )
                                          .sort((a, b) => a.order - b.order)
                                      : []
                                  );
                                }
                              }}
                            >
                              {p.name}
                            </Breadcrumb.Item>
                          </>
                        );
                      })}
                    </Breadcrumb>
                  </>
                )}
              </p>

              <ListGroup className="max-h-200">
                {showBackButton && (
                  <ListGroup.Item
                    action
                    className="fw-bold"
                    onClick={(e) => {
                      console.log({ selectedParent });
                      if (path && path.length > 0) {
                        // Check if data is not null and has items
                        const newData = path.slice(0, -1);
                        setPath(newData);
                      }
                      if (selectedParent) {
                        console.log({ selectedParent });

                        if (parents && parents.length > 0) {
                          const p = parents.filter(
                            (x) => x.levelId == selectedParent.topLevelId
                          );
                        }
                        if (!selectedParent.topLevelId) {
                          setList(
                            parents && parents.length > 0
                              ? parents
                                  .filter((x) => !x.topLevelId)
                                  .sort((a, b) => a.order - b.order)
                              : []
                          );
                          setShowBackButton(false);
                        } else {
                          if (!selectedParent) {
                            setList(
                              parents && parents.length > 0
                                ? parents
                                    .filter(
                                      (x) =>
                                        x.topLevelId ==
                                        selectedParent.topLevelId
                                    )
                                    .sort((a, b) => a.order - b.order)
                                : []
                            );
                          } else {
                            const currParent = parents.find(
                              (x) => x.levelId == selectedParent.topLevelId
                            );
                            if (currParent) {
                              setList(
                                parents && parents.length > 0
                                  ? parents
                                      .filter(
                                        (x) =>
                                          x.topLevelId == currParent.topLevelId
                                      )
                                      .sort((a, b) => a.order - b.order)
                                  : []
                              );
                            }
                          }

                          setShowBackButton(true);
                        }
                      } else {
                        setList(
                          parents && parents.length > 0
                            ? parents
                                .filter((x) => !x.topLevelId)
                                .sort((a, b) => a.order - b.order)
                            : []
                        );
                        setShowBackButton(false);
                      }
                      setSelectedParent(null);
                    }}
                  >
                    Back
                  </ListGroup.Item>
                )}
                {list.map((parent) => {
                  return (
                    <>
                      <ListGroup.Item
                        action
                        onClick={(e) =>
                          setiISelectChanged({
                            levelId: parent.levelId,
                            topLevelId: parent.topLevelId,
                            name: parent.name,
                          })
                        }
                        className="d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">
                            {" "}
                            {parent.order}. {parent.name}
                          </div>
                        </div>
                        <Badge bg="primary" className="me-2" pill>
                          Pages:{" "}
                          {parents
                            ? parents.filter(
                                (x) =>
                                  x.type == 1 && x.topLevelId == parent.levelId
                              ).length
                            : 0}
                        </Badge>
                        <Badge bg="dark" pill>
                          Parents:{" "}
                          {parents
                            ? parents.filter(
                                (x) =>
                                  x.type == 0 && x.topLevelId == parent.levelId
                              ).length
                            : 0}
                        </Badge>
                      </ListGroup.Item>
                    </>
                  );
                })}
              </ListGroup>
              {selectedParent &&
                (parents
                  ? parents.filter(
                      (x) =>
                        x.type == 0 && x.topLevelId == selectedParent.levelId
                    ).length == 0
                  : false) && (
                  <div className="justify-content-between d-flex">
                    <div>
                      <button
                        onClick={(e) => {
                          if (onSelected) {
                            onSelected(selectedParent);
                          }
                        }}
                        className="btn btn-sm bg-gradient-dark"
                      >
                        Change
                      </button>
                    </div>
                    <div>
                      <span className="fw-bold">
                        Selected: {selectedParent.name}
                      </span>
                    </div>
                  </div>
                )}
            </>
          ) : (
            <>nothing found</>
          )}
        </>
      )}
    </>
  );
};
export default ParentsList;
