const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Smooth scrolling behavior
  });
};

export function getTrailData(levelId, data) {
  if (levelId && data && data.length > 0) {
    const topIds = [];
    const top = data.find((c) => c.levelId === levelId);
    if (top) {
      topIds.push(top);
      let topid = top.topLevelId;
      while (topid) {
        const foundTop = data.find((x) => x.levelId === topid);
        if (foundTop) {
          topIds.push(foundTop);
          topid = foundTop.topLevelId;
        } else {
          topid = null;
        }
      }
      return topIds;
    }
  }
  return null;
}
const PageType = {
  title: 0,
  page: 1,
};
export function generateTitleTree(treeItemInfo, isDisplayOnlyTitles = false) {
  let treeDataSource = [];

  if (treeItemInfo && treeItemInfo.length > 0) {
    if (isDisplayOnlyTitles) {
      treeItemInfo = treeItemInfo.filter((x) => x.Type === PageType.title);
    }

    const mains = treeItemInfo.filter((x) => !x.topLevelId);

    if (mains && mains.length > 0) {
      if (!isDisplayOnlyTitles) {
        treeDataSource.push({
          levelId: "main",
          name: "Main page",
          type: PageType.title,
          HasChild: false,
          order: 0,
          topLevelId: null,
        });
      }

      mains.forEach((item) => {
        const kids = getKids(treeItemInfo, item.levelId);
        treeDataSource.push({
          levelId: item.levelId,
          name: item.name,
          HasChild: treeItemInfo.some((y) => y.topLevelId === item.levelId),
          Child: kids.length > 0 ? kids : null,
          type: item.type,
          order: item.order,
          topLevelId: item.topLevelId,
        });
        populateTreeView(treeItemInfo, kids);
      });
      return treeDataSource;
    }
  }
  return [];
}

function getKids(treeItemInfo, levelId) {
  return treeItemInfo
    .filter((x) => x.topLevelId === levelId)
    .map((x) => ({
      levelId: x.levelId,
      name: x.name,
      HasChild: treeItemInfo.some((y) => y.topLevelId === x.levelId),
      type: x.type,
      order: x.order,
      topLevelId: x.topLevelId,
    }));
}

function populateTreeView(treeItemInfo, parents) {
  if (parents) {
    parents.forEach((parentNode) => {
      const gKids = treeItemInfo
        .filter((item) => item.topLevelId === parentNode.levelId)
        .map((x) => ({
          HasChild: treeItemInfo.some((x) => x.topLevelId === x.levelId),
          name: x.name,
          levelId: x.levelId,
          type: x.type,
          order: x.order,
          topLevelId: x.topLevelId,
        }));
      parentNode.Child = gKids.length !== 0 ? gKids : null;
      if (gKids && gKids.length > 0) populateTreeView(treeItemInfo, gKids);
    });
  }
}

export function findSubtree(tree, id) {
  // Helper function to recursively search for the node with the specified NodeId
  function findNode(node) {
    if (node.levelId === id) {
      return node;
    }
    if (node.Child) {
      for (let i = 0; i < node.Child.length; i++) {
        const result = findNode(node.Child[i]);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  // Loop through the entire tree and call the helper function
  for (let i = 0; i < tree.length; i++) {
    const result = findNode(tree[i]);
    if (result) {
      return result;
    }
  }

  // If the node with the specified NodeId is not found, return null
  return null;
}

export function findSubtrees(tree, ids) {
  // Helper function to recursively search for the node with the specified NodeId
  function findNode(node, id) {
    if (node.levelId === id) {
      return node;
    }
    if (node.Child) {
      for (let i = 0; i < node.Child.length; i++) {
        const result = findNode(node.Child[i], id);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  const subtrees = [];

  // Loop through each ID and find the corresponding subtree
  ids.forEach((id) => {
    // Loop through the entire tree and call the helper function
    for (let i = 0; i < tree.length; i++) {
      const subtree = findNode(tree[i], id);
      if (subtree) {
        subtrees.push(subtree);
        break; // Break out of the loop once the subtree is found
      }
    }
  });

  return subtrees;
}

export function getThumbnail(imageId) {
  const userDataStr = localStorage.getItem("userData");

  if (userDataStr) {
    const userData = JSON.parse(userDataStr);
    if (userData && userData.projectId) {
      return `${apiEndpoint}image/thumbnail/${imageId}/${userData.projectId}`;
    }
  }
}

export function getImage(imageId) {
  const userDataStr = localStorage.getItem("userData");

  if (userDataStr) {
    const userData = JSON.parse(userDataStr);
    if (userData && userData.projectId) {
      return `${apiEndpoint}image/${imageId}/${userData.projectId}`;
    }
  }
}
