import React, { useState, useEffect } from "react";
import ComboBox from "../../components/Basic/ComboBox";
import Input from "../../components/Basic/Input";
import Modal from "../../components/Basic/Modal";
import ParentsList from "../../components/ParentsList/ParentsList";

const PageFilter = ({
  projectPagesBasic,
  onFilterChange,
  parents,
  onParentChangeSelected,
  selectOnlyParentWithChild,
  className,
  displayByLocation = true,
}) => {
  const [filter, setFilter] = useState({});
  const [isByParent, setIsByParent] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);
  const statuses = [
    { value: 0, label: "Publish" },
    { value: 1, label: "Draft" },
    { value: 2, label: "Protected by password" },
  ];

  function onParentChangeSelected(parent) {
    setSelectedParent(parent);
    setIsByParent(false);
    if (parent) {
      filter.parentId = parent.levelId;
      onFilterChange(filter);
    }
  }

  return (
    <>
      {isByParent && (
        <Modal
          title="Parent selection"
          show={isByParent}
          onHide={(e) => setIsByParent(false)}
          fullscreen={false}
          hasCloseButton={true}
        >
          <ParentsList
            parents={parents && parents.length > 0 ? parents : []}
            onSelected={onParentChangeSelected}
            selectOnlyParentWithChild={selectOnlyParentWithChild}
            projectPagesBasic={projectPagesBasic}
          />
        </Modal>
      )}
      <div
        className={["col-12 back-white border-r-3 card p-3", className].join(
          " "
        )}
      >
        <h6>Filter</h6>
        <div className="row d-flex mt-2 align-items-center">
          <div className="col">
            <Input
              placeholder="Name"
              value={filter ? filter.name : ""}
              onChange={(e) => {
                filter.name = e.target.value;
                setFilter(filter);
                if (onFilterChange) {
                  onFilterChange(filter);
                }
              }}
            />
          </div>
          <div className="col">
            <ComboBox
              placeholder="Status"
              dataSource={statuses}
              value={filter ? filter.status : ""}
              onChange={(e) => {
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  status: e,
                }));
                if (onFilterChange) {
                  onFilterChange(filter);
                }
              }}
            />
            {/* <SfComboBox Enabled="@(!IsLoadingPages)" TItem="string" TValue="string" Placeholder="Status" DataSource="@(new[]{"Publish", "Draft", "Protect by password"})"
                        ShowClearButton="true">
                <ComboBoxEvents TItem="string" TValue="string" ValueChange="@onFilterStatusChange"></ComboBoxEvents>
            </SfComboBox> */}
          </div>
          {displayByLocation && (
            <div className="col d-flex">
              {projectPagesBasic && projectPagesBasic.length > 0 && (
                <>
                  <a
                    className="btn btn-xsm bg-gradient-dark m-0 me-2"
                    onClick={(e) => setIsByParent(true)}
                  >
                    <p className="fw-bold text-xxs m-0">By location</p>
                  </a>
                  {selectedParent && (
                    <p className="fw-bold text-xxs m-0">
                      Selected: {selectedParent.name}
                    </p>
                  )}
                </>
              )}
            </div>
          )}

          <div className="col">
            <button
              onClick={(e) => {
                onFilterChange(null);
                setFilter({});
              }}
              className="btn btn-xsm bg-gradient-dark mb-1"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default PageFilter;
