import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { convertDate } from "../../Converts/DateTimeConverter";
import { Link, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import AppPagination from "../../components/Basic/AppPagination";
import { getTrailData, scrollToTop } from "../../helpers/GlobalHelper";
import PageFilter from "./PageFilter";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Dropdown,
  Pagination,
} from "react-bootstrap";
import { Skeleton } from "antd";

const Pages = ({
  isLoading,
  projectPagesBasic,
  userLanguage = "fi-FI",
  projectParents,
}) => {
  const [selectedPage, setSelectedPage] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoadingPagin, setIsLoadingPagin] = useState(true);
  const [totalPages, setTotalPages] = useState(Math.ceil(total / itemsPerPage));

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  // Invoke when user click to request another page.

  useEffect(() => {
    if (!isLoading) {
      setItems(projectPagesBasic);
      setTotal(projectPagesBasic ? projectPagesBasic.length : 0);
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setPageCount(
        Math.ceil(
          (projectPagesBasic ? projectPagesBasic.length : 0) / itemsPerPage
        )
      );
      setIsLoadingPagin(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      console.log("running total effect");
      setIsLoadingPagin(true);
      setTotalPages(Math.ceil((items ? items.length : 0) / itemsPerPage));
      setIsLoadingPagin(false);

      // console.log("initiging");
      // setTotal(currentItems ? currentItems.length : 0);
      // setPageCount(Math.ceil(currentItems.length / itemsPerPage));
      // setCurrentPage(1);
      // setCurrentItems(currentItems.slice(0, itemsPerPage));
    }
  }, [items]);

  function onPageItemsChange(value) {
    setItemsPerPage(parseInt(value));
    setPageCount(1);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Update currentPage here
  };

  function onFilterChange(filter) {
    setIsLoadingPagin(true);

    let tmpList = projectPagesBasic;
    console.log(filter);
    if (filter) {
      handlePageChange(1);
      if (filter.name) {
        tmpList = tmpList.filter(
          (x) =>
            x.pages &&
            x.pages.length > 0 &&
            x.pages.filter((p) =>
              p.title.toLowerCase().includes(filter.name.toLowerCase())
            ).length > 0
        );
      }

      if (filter.status) {
        if (filter.status == 0) {
          tmpList = tmpList.filter(
            (x) =>
              x.pages.length > 0 &&
              x.pages.filter((p) => p.publishDate).length > 0
          );
        }
        if (filter.status == 1) {
          tmpList = tmpList.filter(
            (x) =>
              x.pages.length > 0 &&
              x.pages.filter((p) => p.isProtectedByPassword).length > 0
          );
        }
        if (filter.status == 2) {
          tmpList = tmpList.filter(
            (x) =>
              x.pages.length > 0 &&
              x.pages.filter((p) => p.draftDate).length > 0
          );
        }
      }
      if (filter.parentId) {
        console.log({ filter });
        tmpList = tmpList
          ? tmpList.filter(
              (x) => x.title && x.title.topLevelId == filter.parentId
            )
          : [];
      }

      setTotalPages(Math.ceil((tmpList ? tmpList.length : 0) / itemsPerPage));
      setItems(tmpList);
    } else {
      setItems(projectPagesBasic);
      setTotalPages(
        Math.ceil(
          (projectPagesBasic ? projectPagesBasic.length : 0) / itemsPerPage
        )
      );
    }
    setIsLoadingPagin(false);
  }

  // const Padgin = () => {
  //   return (
  //     <div className="col-md-12">
  //       <nav aria-label="Page navigation comments" className="mt-4">
  //         <ReactPaginate
  //           previousLabel="previous"
  //           nextLabel="next"
  //           breakLabel="..."
  //           breakClassName="page-item"
  //           breakLinkClassName="page-link"
  //           pageCount={pageCount}
  //           pageRangeDisplayed={4}
  //           marginPagesDisplayed={2}
  //           onPageChange={handlePageClick}
  //           containerClassName="pagination justify-content-center"
  //           pageClassName="page-item"
  //           pageLinkClassName="page-link"
  //           previousClassName="page-item"
  //           previousLinkClassName="page-link"
  //           nextClassName="page-item"
  //           nextLinkClassName="page-link"
  //           activeClassName="active"
  //           // eslint-disable-next-line no-unused-vars
  //           hrefBuilder={(page, pageCount, selected) =>
  //             page >= 1 && page <= pageCount ? `/page/${page}` : "#"
  //           }
  //           hrefAllControls
  //           onClick={(clickEvent) => {
  //             console.log("onClick", clickEvent);
  //             // Return false to prevent standard page change,
  //             // return false; // --> Will do nothing.
  //             // return a number to choose the next page,
  //             // return 4; --> Will go to page 5 (index 4)
  //             // return nothing (undefined) to let standard behavior take place.
  //           }}
  //         />
  //       </nav>
  //     </div>
  //   );
  // };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton />
        </>
      ) : (
        <>
          {" "}
          <div className="row">
            <PageFilter
              projectPagesBasic={projectPagesBasic}
              onFilterChange={onFilterChange}
              parents={projectParents}
              selectOnlyParentWithChild={true}
              className="mb-3"
            />
          </div>
          {items && items.length > 0 ? (
            <div className="">
              <div className="row">
                <div className="col-md-5">
                  <ListGroup as="ol">
                    {items
                      .slice(
                        (currentPage - 1) * itemsPerPage,
                        (currentPage - 1) * itemsPerPage + itemsPerPage
                      )
                      .map((x) => {
                        const trail = getTrailData(
                          x.title.levelId,
                          projectPagesBasic.map((x) => x.title)
                        );
                        return (
                          <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                          >
                            <div className="ms-2 me-auto">
                              <div>
                                <a
                                  className="fw-bold"
                                  onClick={(e) => {
                                    scrollToTop();
                                    setSelectedPage(x);
                                  }}
                                >
                                  {x.title.name}
                                </a>
                              </div>
                              Languages:{" "}
                              {x.languages && x.languages.length > 0
                                ? x.languages.length
                                : 0}
                              {trail && trail.length > 0 && (
                                <>
                                  {trail.map((parent) => {
                                    return (
                                      <div>
                                        <span className="fw-bold">
                                          {parent.name}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                            <div>
                              <a class="btn btn-link text-dark ps-0 pe-0 cursor-disable me-2">
                                <i class="bi bi-pencil-square font-xl app-icon"></i>
                              </a>

                              <a class="btn btn-link text-dark ps-0 pe-0 cursor-disable">
                                <i class="bi bi-trash3-fill font-xl app-icon"></i>
                              </a>
                            </div>
                          </ListGroup.Item>
                        );
                      })}
                  </ListGroup>

                  <div className="mt-3">
                    <div className="row">
                      <div className="col">
                        {items && items.length > itemsPerPage && (
                          <>
                            {isLoadingPagin ? (
                              <>loading..</>
                            ) : (
                              <AppPagination
                                total={total}
                                itemsPerPage={itemsPerPage}
                                onPageChange={handlePageChange}
                                totalPages={totalPages}
                              />
                            )}
                          </>
                        )}
                      </div>
                      <div className="col">
                        <div>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Control
                              as="select"
                              defaultValue="5"
                              onChange={(e) => {
                                console.log(e.target.value);
                                onPageItemsChange(e.target.value);
                              }}
                            >
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="20">20</option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col">
                        <span className="fw-bold mt-3">
                          Total:
                          {items ? items.length : 0}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  {selectedPage &&
                    selectedPage.pages &&
                    selectedPage.pages.length > 0 && (
                      <ListGroup as="ol" numbered>
                        {selectedPage.pages.map((page) => {
                          return (
                            <ListGroup.Item
                              as="li"
                              className="d-flex justify-content-between align-items-start"
                            >
                              <div className="ms-2 me-auto">
                                <div>
                                  <Link
                                    className="fw-bold"
                                    to={`page-wizard/${page.levelId}/${page.topLevelId}/${page.languageId}`}
                                  >
                                    {page.title}
                                  </Link>
                                </div>
                                {page.languageId}
                                <div>
                                  {page.publishDate ? (
                                    <span class="badge bg-success">
                                      Published
                                    </span>
                                  ) : (
                                    <span class="badge bg-danger">
                                      Not publish
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="me-3">
                                <div>
                                  <span className="fw-bold">Create:</span>{" "}
                                  {convertDate(page.createDate)}
                                </div>
                                {page.updateDate && (
                                  <div>
                                    <span className="fw-bold">Updated:</span>{" "}
                                    {convertDate(page.updateDate)}
                                  </div>
                                )}
                                {page.publishDate && (
                                  <div>
                                    <span className="fw-bold">Published:</span>{" "}
                                    {convertDate(page.publishDate)}
                                  </div>
                                )}
                              </div>
                              <div>
                                <a class="btn btn-link text-dark ps-0 pe-0 cursor-disable me-2">
                                  <i class="bi bi-pencil-square font-xl app-icon"></i>
                                </a>
                                <a class="btn btn-link text-dark ps-0 pe-0 cursor-disable">
                                  <i class="bi bi-trash3-fill font-xl app-icon"></i>
                                </a>
                              </div>
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    )}
                </div>
              </div>
            </div>
          ) : (
            <>Empty</>
          )}
        </>
      )}
    </>
  );
};
export default Pages;
