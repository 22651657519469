import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [website, setWebsite] = useState(
    localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData")).websiteName
      : null
  );

  return (
    <>
      <aside
        className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark"
        id="sidenav-main"
        data-color="primary"
      >
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
            onClick={(e) => {
              const iconNavbarSidenav =
                document.getElementById("iconNavbarSidenav");
              const iconSidenav = document.getElementById("iconSidenav");
              const sidenav = document.getElementById("sidenav-main");
              let body = document.getElementsByTagName("body")[0];
              let className = "g-sidenav-pinned";

              if (body.classList.contains(className)) {
                body.classList.remove(className);
                setTimeout(function () {
                  sidenav.classList.remove("bg-white");
                }, 100);
                sidenav.classList.remove("bg-transparent");
              } else {
                body.classList.add(className);
                sidenav.classList.remove("bg-transparent");
                iconSidenav.classList.remove("d-none");
              }
            }}
          ></i>
          {website && (
            <a className="navbar-brand m-0" href="/projects">
              <span className="ms-1 font-weight-bold text-white">
                {website}
              </span>
            </a>
          )}
          {/* @if (!string.IsNullOrEmpty(website))
        {
            
        } */}
        </div>
        <hr className="horizontal light mt-0 mb-2" />
        <div
          className="collapse navbar-collapse  w-auto h-auto"
          id="sidenav-collapse-main"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/dashboard" className="nav-link text-white">
                <i className="bi bi-speedometer2 app-icon"></i>
                <span className="nav-link-text ms-2 ps-1">Dashboard</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/page-wizard" className="nav-link text-white">
                <i className="bi bi-file-earmark-plus app-icon"></i>
                <span className="nav-link-text ms-2 ps-1">Add page</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/pages" className="nav-link text-white">
                <i className="bi bi-file-text app-icon"></i>
                <span className="nav-link-text ms-2 ps-1">Pages</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/titles" className="nav-link text-white">
                <i className="bi bi-file-text app-icon"></i>
                <span className="nav-link-text ms-2 ps-1">Titles</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/automation" className="nav-link text-white">
                <i className="bi bi-gear-wide-connected app-icon"></i>
                <span className="nav-link-text ms-2 ps-1">Automation</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/images-storage" className="nav-link text-white">
                <i className="bi bi-images app-icon"></i>
                <span className="nav-link-text ms-2 ps-1">Images storage</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/messages" className="nav-link text-white">
                <i className="bi bi-envelope app-icon"></i>
                {/* @if (Notifications != null && Notifications.Where(x => !x.OpenByAppDate.HasValue).Any(x => x.Type == NotificationType.Message.ToString()))
                    {
                        <span className="top-5 start-100 translate-middle badge rounded-pill bg-danger border border-white small py-1 px-2">
                            <span className="small">@Notifications.Where(x=> !x.OpenByAppDate.HasValue).Count(x => x.Type == NotificationType.Message.ToString())</span>
                            <span className="visually-hidden">unread notifications</span>
                        </span>
                    } */}
                <span className="nav-link-text ms-2 ps-1">Messages</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/commments" className="nav-link text-white">
                <i className="bi bi-chat-dots app-icon"></i>
                {/* @if (Notifications != null && Notifications.Where(x => !x.OpenByAppDate.HasValue).Any(x => x.Type == NotificationType.Comment.ToString()))
                    {
                        <span className="top-5 start-100 translate-middle badge rounded-pill bg-danger border border-white small py-1 px-2">
                            <span className="small">@Notifications.Where(x=> !x.OpenByAppDate.HasValue).Count(x => x.Type == NotificationType.Comment.ToString())</span>
                            <span className="visually-hidden">unread notifications</span>
                        </span>
                    } */}
                <span className="nav-link-text ms-2 ps-1">Commments</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/translation" className="nav-link text-white">
                <i className="bi bi-translate app-icon"></i>
                <span className="nav-link-text ms-2 ps-1">Translation</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/views" className="nav-link text-white">
                <i className="bi bi-bar-chart-fill app-icon"></i>
                <span className="nav-link-text ms-2 ps-1">Views</span>
              </Link>
            </li>

            <li className="nav-item">
              <a
                data-bs-toggle="collapse"
                href="#pagesExamples"
                className="nav-link text-white "
                aria-controls="pagesExamples"
                role="button"
                aria-expanded="false"
              >
                <i className="bi bi-gear app-icon"></i>
                <span className="nav-link-text ms-2 ps-1">Site & Settings</span>
              </a>
              <div className="collapse " id="pagesExamples">
                <ul className="nav ">
                  <li className="nav-item ">
                    <Link to="/site" className="nav-link text-white">
                      <span className="sidenav-mini-icon"> S </span>
                      <span className="sidenav-normal  ms-2  ps-1"> Site </span>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link to="/languages" className="nav-link text-white">
                      <span className="sidenav-mini-icon"> L </span>
                      <span className="sidenav-normal  ms-2  ps-1">
                        {" "}
                        Languages{" "}
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link to="/social-media" className="nav-link text-white">
                      <span className="sidenav-mini-icon"> SM </span>
                      <span className="sidenav-normal  ms-2  ps-1">
                        {" "}
                        Social Media{" "}
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <Link to="/main-page" className="nav-link text-white">
                <i className="bi bi-caret-up-square-fill app-icon"></i>
                <span className="nav-link-text ms-2 ps-1">Main page</span>
              </Link>
            </li>
            <li className="nav-item">
              <a
                data-bs-toggle="collapse"
                href="#sitePoliciesNavItem"
                className="nav-link text-white "
                aria-controls="sitePoliciesNavItem"
                role="button"
                aria-expanded="false"
              >
                <i className="bi bi-bank app-icon"></i>
                <span className="nav-link-text ms-2 ps-1">Site Policies</span>
              </a>
              <div className="collapse " id="sitePoliciesNavItem">
                <ul className="nav ">
                  <li className="nav-item ">
                    <Link
                      to="/page/cbc94d94-32e4-4f97-b550-09c868b57e67"
                      className="nav-link text-white"
                    >
                      <span className="sidenav-mini-icon"> PP </span>
                      <span className="nav-link-text ms-2 ps-1">
                        Privacy Policy
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link
                      to="/page/336acbe4-edc8-4530-a36c-62ca0a05a179"
                      className="nav-link text-white"
                    >
                      <span className="sidenav-mini-icon"> CP </span>
                      <span className="nav-link-text ms-2 ps-1">
                        Cookies Policy
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link
                      to="/page/ceefb13d-6249-4774-b698-fbaf3b647f96"
                      className="nav-link text-white"
                    >
                      <span className="sidenav-mini-icon"> TC </span>
                      <span className="nav-link-text ms-2 ps-1">
                        Terms and condition
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-white"
                href="https://identity.omadot.fi/Identity/Account/Manage"
              >
                <i className="bi bi-person-fill app-icon"></i>
                <span className="nav-link-text ms-2 ps-1">My account</span>
              </a>
            </li>
            {/* <AuthorizeView
              Policy="@ProjectRole.Finance.ToString()"
              Resource="@((int)ProjectRole.Finance)"
            >
              <Authorized>
                <li className="nav-item">
                  <a href="/billing" className="nav-link text-white ">
                    <i className="bi bi-bank app-icon"></i>
                    <span className="nav-link-text ms-2 ps-1">Billing</span>
                  </a>
                </li>
              </Authorized>
            </AuthorizeView>
            <AuthorizeView
              Policy="@ProjectRole.Admin.ToString()"
              Resource="@((int)ProjectRole.Admin)"
            >
              <Authorized>
                <li className="nav-item">
                  <a href="/team-member" className="nav-link text-white ">
                    <i className="bi bi-person-fill app-icon"></i>
                    <span className="nav-link-text ms-2 ps-1">Team members</span>
                  </a>
                </li>
              </Authorized>
            </AuthorizeView> */}

            <li className="nav-item">
              <Link
                to="/activate-invitation-code"
                className="nav-link text-white"
              >
                <i className="bi bi-people-fill app-icon"></i>
                <span className="nav-link-text ms-2 ps-1">
                  Activate invitation code
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};
export default Sidebar;
